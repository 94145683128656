@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/ProductFilters/index.js
////

/// styles for product filters

.product-filters {
    $filter-spacing: 25px;

    margin-bottom: 1rem;

    .filter-label {
        display: block;
        clear: both;
        padding-bottom: $element-spacing-em;
    }

    .filter-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        clear: both;
    }

    .query-type {
        @include for-screen-gte__xxl { // min-width 1600px
            margin-top: ($filter-spacing * 3);
        }

        @include for-screen-gte__hd { // min-width 1920px
            margin-top: ($filter-spacing * 4);
        }

        @include for-screen-lt__xxl { // max-width 1600px
            padding-top: 1rem;
        }

        @include for-screen-gte__filter-breakpoint { // max-width 1023px
            margin-top: $filter-spacing;
        }

        .line-input {
            display: block;
            border: 0 solid $base-color__border_grey;
            border-bottom-width: 1px;
        }

        .product-query {
            position: relative;
            display: flex;
            align-items: baseline;
            margin: 0 $element-spacing-em $element-spacing-em 0;

            .txt {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 0;
                flex-basis: 7em;
                text-align: left;
            }

            .line {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                margin-top:auto;
                margin-bottom: 1rem;
                padding-right: 1rem;

                input {
                    width: 100%;
                    padding: 1.5em $element-spacing-em .6em;
                }
            }

            .line-btn {
                position: absolute;
                bottom: 1rem;
                right: 1rem;
                line-height: 1;
                color: $base-color__border_grey;
                padding-top: .25em;
                padding-bottom: .25em;

                @include for-screen-gte__hd { // min-width 1920px
                    padding-top: .5em;
                }

                @include for-screen-lt__xxl { // max-width 1600px
                    padding-top: .4em;
                }

                @include set-colors($base-color__border_grey, transparent, transparent);
                @include set-colors-hover($base-color__text_light, $base-color__bg_dark, $base-color__border_grey);

                i {
                    font-size: 2.45rem;
                    color: inherit;
                }

                svg.search {
                    @include for-screen-lt__xxl { // max-width 1600px
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    } // query-type

    .contact-stores-link-container,
    .area-type {
        display: inline-block;

        @include for-screen-gte__filter-breakpoint {
            margin-top: ($filter-spacing * 2);
        }

        @include for-screen-gte__hd {
            margin-top: ($filter-spacing * 3);
        }
    }

    .contact-stores-link-container {
        float: right;
        display: inline-flex;
        gap: $grid;

        @include for-screen-lt__filter-breakpoint { // max-width 1023px
            float: none;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            gap: 0;

            > * {
                width: 100%;
            }

            .dropdown-trigger .button .label {
                text-align: center;
            }
        }
    }

    .link-generator {
        clear: both;
    }

    .style-selection {
        float: right;
        max-width: 400px;
        margin-top: $filter-spacing;

        @include for-screen-gte__filter-breakpoint { // min-width 1023px
            margin-top: ($filter-spacing * 2)-10;
        }

        @include for-screen-gte__xl { // min-width 1250px
            margin-top: ($filter-spacing * 3)-10;
        }

        @include for-screen-gte__xxl { // min-width 1600px
            margin-top: ($filter-spacing * 3);
        }

        .button {
            width: 100%;

            @include for-screen-gte__filter-breakpoint { // min-width 1023px
                font-size: .93rem;
            }

            @include for-screen-gte__xl { // min-width 1250px
                font-size: 1rem;
            }

            &.is-close {
                width: auto;
            }
        }

        .btn-text {
            padding: 0 ($element-spacing-em * 2) $element-spacing-em $element-spacing-em-half
        }
    } // style-selection

    .filter-btn-area,
    .radius-btn-area {
        @include for-screen-gte__filter-breakpoint {
            display: none;
        }
    }

    .product-filter-list {
        .dropdown-item {
            display: flex;
            align-items: center;

            label {
                @extend %text-light;

                cursor: pointer;
                width: 100%;
                margin-bottom: $element-spacing-em;
                padding-left: 0;
            }

            // label text
            .label {
                display: block;
                padding-right: ($form-check-radio-size + 2);
            }

            input {
                cursor: pointer;
                right: 0;
                left: auto;
                margin-bottom: $element-spacing-em;
            }

            &.group {
                span {
                    display: block;
                    width: 100%;
                    font-size: 120%;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    margin: $grid-half 0;
                }

                &:first-of-type {
                    span {
                        margin-top: 0;
                    }
                }
            }
        }
        .contact-stores-link-container .button,
        .current-filter-url,
        .button-select {
            @include for-screen-gte__filter-breakpoint {
                font-size: .83rem;
            }

            @include for-screen-gte__xl {
                font-size: .92rem;
            }

            @include for-screen-gte__xxl {
                font-size: 1rem;
            }
        }

        .filter-type {
            > div {
                &:last-of-type {
                    .dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }

        .current-filter-url {
            margin-left: 0.5rem;
        }
    } // product-filter-list

    .product-query {
        display: flex;

        i {
            cursor: pointer;
        }
    }

    .selected-filter-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;

        .selected-filter-item {
            display: flex;
            cursor: pointer;
            margin-right: 1.5rem;
            margin-bottom: $grid;
            padding-left: 0;

            svg {
                margin-left: .5rem;

                path {
                    stroke-width: 4px;
                }
            }
        }

        // clear filter button
        .clear-all {
            margin-top: -.5em;
            padding-left: .5rem;

            @include for-screen-gte__filter-breakpoint {
                font-size: .83rem;
            }

            @include for-screen-gte__xl {
                font-size: .92rem;
            }

            @include for-screen-gte__xxl {
                font-size: 1rem;
            }

            svg {
                margin-top: .4em;
                path {
                    stroke: #fff;
                }
            }


            &:hover {
                svg path {
                    stroke: #000;
                }
            }
        }
    } // selected-filter-list

    .dropdown {
        display: inline-flex;
        position: relative;
        vertical-align: top;

        .button {
            @include for-screen-gte__hd {
                font-size: $font-size__hd_20;
            }

            &:focus,
            &:active {
                @include set-colors($base-color__text_dark,$base-color__bg_light, $base-color__border_dark);

                svg path {
                    stroke: #000;
                }
            }
        }

        .dropdown-trigger {
            button {
                @include for-screen-gte__filter-breakpoint {
                    padding-left: .5em;
                    padding-right: .5em;
                    font-size: .83rem;
                }

                @include for-screen-gte__xl {
                    font-size: .92rem;
                }

                @include for-screen-gte__xxl {
                    font-size: 1rem;
                }
            }
        }

        .dropdown-menu {
            display: none;
            left: 0;
            min-width: 12rem;
            position: absolute;
            top: 100%;
            z-index: 20;
        }

        .dropdown-content {
            background-color: $base-color__bg_light;
            border: 1px solid $base-color__border_grey;
            margin-top: calc($grid / 3);
            padding: ($element-spacing-em + .25) $element-spacing-em 0;
            max-height: 50vh;
            overflow-y: auto;
        }

        &.is-hoverable:hover,
        &.is-active {
            .dropdown-menu {
                @include for-screen-gte__filter-breakpoint {
                    display: block;
                }
            }

            .button {
                @include set-colors($base-color__text_light, $base-color__bg_dark, $base-color__border);

                svg path {
                    stroke: #fff;
                }
            }
        }

        &.is-right {
            .dropdown-menu {
                left: auto;
                right: 0;
            }
        }

        &.is-up {
            .dropdown-menu {
                bottom: 100%;
                padding-bottom: 4px;
                padding-top: initial;
                top: auto;
            }
        }
    } // dropdown


/* + + + + + + + + + + + + + + + + + + + + + */
/* + + + + +  mobile  filter menu  + + + + + */

    @include for-screen-lt__filter-breakpoint { // max-width 1023px
        .product-filter-list {
            border: solid $base-color__border_grey;
            border-width: 0 1px 0 1px;

            .button {
                display: block;
                width: 100%;

                .label {
                    display: block;
                    text-align: left;
                    padding-left: $grid-half;
                }

                div {
                    text-align: right;

                    svg {
                        width: 22px;
                        height: 11px;
                    }
                }
            }

            .filter-type {
                .button {
                    border-width: 0 0 1px;
                }
            }
        }

        .contact-stores-link-container {
            .dropdown-menu {
                border-bottom: 1px solid $base-color__border_grey;
            }
        }

        .link-generator {
            margin: 0;
            border-bottom: 1px solid $base-color__border_grey;
        }

        .contact-stores-link-container,
        .style-selection,
        .filter-btn-area,
        .radius-btn-area {
            .button {
                border-width: 0 0 1px;
            }
        }

        .style-selection {
            float: none;
            max-width: none;
            margin: 0;

            .btn-text {
                display: none;
            }
        }

        .query-type {
            .product-query {
                margin: 0 0 $element-spacing-em 0;

                .txt {
                    display: none;
                }

                .line {
                    padding-left: 1rem;
                    padding-right: 0;

                    input {
                        padding-left: 0;
                    }
                }

                .line-btn {
                    position: relative;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    padding-right: $element-spacing-em;
                    padding-bottom: 0;

                    svg {
                        width: 21px;
                        height: 21px;
                    }
                }
            }
        }

        .filter-label {
            display: none;
        }

        .filter-type {
            display: block;
        }

        .dropdown {
            display: block;
            width: 100%;

            .dropdown-menu {
                display: none;
                left: 0;
                min-width: auto;
                width: 100%;
                position: static;
            }

            &.is-active {
                .dropdown-menu {
                    display: block;
                }
            }

            .dropdown-content {
                margin-top: 0;
                border-width: 1px 0 0 0;
            }

            .dropdown-trigger {
                button {
                    @include for-screen-lt__filter-breakpoint {
                        padding-right: $element-spacing-em;
                    }
                }
            }
        }

        .area-type {
            padding: $grid;
        }

        /* toggle filter and radius */
        .product-filter-list {

            .query-type,
            .filter-type,
            .area-type {
                display: none;
            }

            &.filter-active {
                .query-type,
                .filter-type {
                    display: block;
                }
            }

            &.radius-active {
                .area-type {
                    display: block;
                }
            }
        }
    } // max-width 1250px
} // product-filters


/* + + + + + + + + + + + + + + + + + + + + + + + + + */
/* + + + + +  sticky filter  (body class)  + + + + + */

.product-filters-sticky {
    .navbar-footer {
        .section {
            @include for-screen-gte__filter-breakpoint {
                margin-bottom: 260px; // for showing the whole store-item image
            }
        }
    }

    .main-content .section {
        &.product-filters {
            left: 0;
            right: 0;
            z-index: 20;
            max-width: none;
            width: auto;
        }
    }

    .product-filters {
        border-top: 1px solid $base-color__border_lightgrey;

        @include for-screen-gte__filter-breakpoint { // min-width 1250px
            position: fixed;
            top: $navbar-height-xl;
            margin-top: 0;
            padding-bottom: $grid;
        }

        @include for-screen-gte__xl { // min-width 1250px
            top: $navbar-height-hd;
        }

        @include for-screen-gte__xxl { // min-width 1600px
            top: $navbar-height-hd;
            margin-top: $grid;
        }

        @include for-screen-gte__hd { // min-width 1920px
            margin-top: ($grid * 2);
        }

        .text-content {
            position: relative;

            @include for-screen-gte__filter-breakpoint { // min-width 1023px
                width: 100%;
                max-width: ($container-max-width-xl - ($grid * 4));
                background: $base-color__bg_light;
                margin: 0 auto;
                padding-bottom: 0;
                padding-left: (2*$grid);
            }

            @include for-screen-gte__xl {
                padding-left: 0;
            }

            @include for-screen-gte__xxl { // min-width 1600px
                max-width: ($container-max-width-xxl - ($grid * 4));
            }

            @include for-screen-gte__hd { // min-width 1920px
                max-width: ($container-max-width-hd - ($grid * 8));
            }
        }


/* + + + + + + + + + + + + + + + + + + + + + + + + + + + + */
/* + + + + +  only media query min-width 1023px  + + + + + */

        @include for-screen-gte__filter-breakpoint {
            $filter-btn-area-width: 30.333%;
            $area-type-width: 39.333%;
            $style-selection-width: (100 - $filter-btn-area-width - $area-type-width);

            // col 1
            .filter-btn-area {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                max-width: $filter-btn-area-width;
                width: $filter-btn-area-width;
                background-color: $base-color__bg_light;
                margin: 0;
                padding: 0;
            }

            // col 2
            .area-type {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: $filter-btn-area-width;
                display: block;
                max-width: $area-type-width;
                width: $area-type-width;
                background-color: $base-color__bg_light;
                margin: 0;
                padding: 0 $grid-half;

                .button-select {
                    background-color: $base-color__bg_light;
                    border: 0 none;
                    font-size: .88rem;
                }

                label {
                    span {
                        font-size: $font-size__hd_12;
                    }
                }
            }

            // col 3
            .style-selection {
                box-sizing: border-box;
                position: absolute;
                left: ($filter-btn-area-width + $area-type-width);
                z-index: 5;
                max-width: $style-selection-width;
                width: $style-selection-width;
                margin: 0;

                .btn-area {
                    .button {
                        border-width: 0 1px 1px 1px;
                    }
                }
            }

            .style-selection .btn-area .button,
            .area-type,
            .filter-btn-area .button {
                max-height: 39px;
                height: 39px;
                border-bottom: 1px solid $base-color__border_lightgrey;
                border-color: $base-color__border_lightgrey;
            }

            .query-type,
            .btn-text {
                    display: none;
            }

            .filter-btn-area {
                .button {
                    width: 100%;
                    border-width: 0 1px 1px 1px;
                }
            }

            .filter-label {
                font-size: $font-size__hd_12;
                padding-top: $grid;
            }

            .selected-filter-wrapper {
                .label,
                .selected-filter-list {
                    .clear-all,
                    .selected-filter-item {
                        font-size: $font-size__hd_12;
                    }

                    .clear-all {
                        margin-top: -9px;
                    }

                    .selected-filter-item {
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }

           .product-filter-list {
                .button {
                    font-size: $font-size__hd_12;
                    padding: 0.1em 0.25em;

                    svg {
                        &.arrow-down,
                        &.arrow-up {
                            width: 18px;
                            height: 8px;
                        }
                    }
                }

                .filter-type {
                    justify-content: flex-start;
                    padding-top: $grid;
                    padding-bottom: $grid;
                }

                .dropdown {
                    padding-right: $grid-half;

                    label {
                        font-size: $font-size__hd_12;
                    }

                    .dropdown-content {
                        padding-top: $element-spacing-em;
                        padding-bottom: .25em;
                    }
                }

                /* toggle for filter settings */
                .filter-label,
                .filter-type,
                 + .selected-filter-wrapper {
                    display: none;
                }

                &.filter-active {
                    .filter-label,
                    .filter-type,
                   + .selected-filter-wrapper {
                        display: block;
                    }
                }
            }

            .selected-filter-wrapper {
                > .label {
                    margin-top: 0;
                }
            }
        } // min-width 1023px
    } // product-filters
} // product-filters-sticky
