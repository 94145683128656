.gallery-container {
    $animation-duration: 200ms;

    overflow: hidden;

    &:empty {
        display: none;
    }

    @include for-screen-lt__md {
        display: none;
    }

    .gallery {
        position: relative;

        .nav-buttons {
            button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: none;

                &:focus,
                &:hover {
                    background: none;
                }
            }

            .previous {
                left: 0;
            }

            .next {
                right: 0;
            }
        }

        &.animate {
            .slides {
                transition: transform $animation-duration ease-in-out;
            }
        }

        .slides {
            display: flex;
            gap: 1px;

            transform: translateX(var(--translate));

            .slide {
                position: relative;
                display: flex; /* to get rid of this small empty space on the bottom, no one knows where it comes from */
                flex: 0 0 60%;

                .background {
                    display: grid;

                    img,
                    video {
                        grid-area: 1 / 1;
                        width: 100%;
                        aspect-ratio: 16 / 9;
                        object-fit: cover;
                    }

                    video {
                        visibility: hidden;
                    }
                }

                &.playing {
                    .background {
                        video {
                            visibility: visible;
                        }
                    }
                }

                .content {
                    $space: 1rem;

                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    gap: $space;
                    padding: $space;

                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 50%);

                    .text {
                        h3,
                        p {
                            margin: 0;
                            padding: 0;
                            display: block;
                            color: white;
                            font-family: inherit;
                        }

                        h3 {
                            text-transform: uppercase;
                            font-family: $font-family_serif;
                            font-size: 1.8rem;
                        }

                        p {
                            margin-top: 1rem;
                            font-family: $font-family_sans;
                            font-size: 1rem;
                            font-weight: 300;
                        }
                    }

                    .buttons {
                        .button {
                            color: black;
                            background-color: white;
                            border-color: $base-color__border_grey;

                            :focus,
                            &:hover {
                                color: white;
                                background-color: black;
                            }
                        }
                    }
                }
            }
        }
    }
}
